<template>
    <div class="pca-wrapper">
        <div class="pca-item pca-p">
            <el-select v-model="provincecode" placeholder="省" filterable @change="getCity">
                <el-option
                    v-for="item in provinceList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                />
            </el-select>
        </div>
        <div class="pca-item pca-p">
            <el-select v-model="citycode" placeholder="市" filterable @change="getArea">
                <el-option
                    v-for="item in cityList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                />
            </el-select>
        </div>
        <div class="pca-item pca-p">
            <el-select v-model="areacode" placeholder="区" filterable @change="setAddress">
                <el-option
                    v-for="item in areaList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                />
            </el-select>
        </div>
    </div>
</template>
<script>
import { getProvince, getCity, getArea } from '@api/my'
export default {
    name: 'ProvinceCityArea',
    props: {
        provincecodeDefault: {
            type: String,
            default: ''
        },
        citycodeDefault: {
            type: String,
            default: ''
        },
        areacodeDefault: {
            type: String,
            default: ''
        },
    },
    watch: {
        provincecodeDefault(val, oldVal) {
            if(val) this.setProvince()
        },
        // citycodeDefault(val, oldVal) {
        //     this.setProvince()
        // },
        // areacodeDefault(val, oldVal) {
        //     this.setProvince()
        // },
    },
    created() {
        this.getProvince()
    },
    data() {
        return {
            provincecode: this.provincecodeDefault,
            provinceName: '',
            citycode: this.citycodeDefault,
            cityName: '',
            areacode: this.areacodeDefault,
            areaName: '',
            provinceList: [],
            cityList: [],
            areaList: [],
	   
        }
    },
    methods: {
        // 获取省
        async getProvince() {
            console.log(this.provincecodeDefault, this.citycodeDefault, this.areacodeDefault)
            const res = await getProvince()
            this.provinceList = res.detail
            // if (this.provinceList[0]) {
                this.setProvince()
            // }
        },
        setProvince() {
            if (this.provinceList[0]) {
                const provinceObj = this.provinceList.find(item => item.code === this.provincecodeDefault) || this.provinceList[0]
                this.provincecode = provinceObj.code
                this.provinceName = provinceObj.name
                this.getCity()
            }
	    
	    
	    
        },
        // 获取市
        async getCity() {
            if (!this.provincecode) {
                this.$notify({ title: '提示', type: 'warning', message: '请先选择省', position: 'bottom-right' })
                return
            }
            const provinceObj = this.provinceList.find(item => item.code === this.provincecode)
            // this.provincecode = provinceObj.code
            this.provinceName = provinceObj.name
            const res = await getCity({ provincecode: this.provincecode })
            this.cityList = res.detail
            if (this.cityList[0]) {
                const cityObj = this.cityList.find(item => item.code === this.citycodeDefault) || this.cityList[0]
                this.citycode = cityObj.code
                this.cityName = cityObj.name
                this.getArea()
            }
        },
        // 获取区
        async getArea() {
            if (!this.citycode) {
                this.$notify({ title: '提示', type: 'warning', message: '请先选择市', position: 'bottom-right' })
                return
            }

            const res = await getArea({ citycode: this.citycode })
	    this.cityName = this.byCodeGetCity(this.citycode) // 重新对城市名称赋值  
            this.areaList = res.detail
            //if (this.areaList[0]) {
            //    const areaObj = this.areaList.find(item => item.code === this.areacodeDefault) || this.areaList[0]
            //    this.areacode = areaObj.code
            //    this.areaName = areaObj.name
            //   this.setAddress(this.areacode)
            //}
	    this.setArea(this.areacodeDefault)
	    
        },
        setArea(areacode) {
            if (this.areaList[0]) {
                const areaObj = this.areaList.find(item => item.code === areacode) || this.areaList[0]
                this.areacode = areaObj.code
                this.areaName = areaObj.name

		this.setAddress(areaObj.code)
            }
	    
        },
        byCodeGetCity(code) {
            return this.cityList.filter(item => item.code === code)[0].name
        },
        byCodeGetArea(code) {
            return this.areaList.filter(item => item.code === code)[0].name
        },
        setAddress(areacode) {
	    //window.alert(areacode)
	    //this.setArea(areacode)

	    this.areaName = this.byCodeGetArea(this.areacode) // 重新对城市名称赋值  
            console.log('areacode', areacode)
            const addressObj = {
                province: {
                    name: this.provinceName,
                    code: this.provincecode
                },
                city: {
                    name: this.cityName,
                    code: this.citycode
                },
                area: {
                    name: this.areaName,
                    code: this.areacode
                }
            }
	    //window.alert(addressObj.area.name)
	    //return false;
            this.$emit('setFullAddress', addressObj)
        },
        // reset() {
        //     this.provincecode = ''
        //     this.citycode = ''
        //     this.areacode = ''
        // }
    }
}
</script>
<style lang="scss" scoped>
.pca-wrapper {
    display: inline-block;
    width: 100%;
}
.pca-item {
    @include inlineBlock();
    width: calc(100% / 3);
}
</style>
